
import { defineComponent } from 'vue';
import { triggerBatchVideosCsv } from '@/services/api';

export default defineComponent({
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openCSVFileInput() {
      // Trigger the input element for selecting CSV files
      const csvInput = this.$refs.csvInput as HTMLInputElement;
      csvInput.click();
    },
    async handleCSVFileInput(event) {
      const files = event.target.files;

      if (files.length > 0) {
        const file = files[0]; // Assuming you are handling a single CSV file
        const formData = new FormData();
        formData.append('file', file);

        try {
          const exportSuccessful = await triggerBatchVideosCsv(formData);

          if (exportSuccessful) {
            // Handle success (e.g., show a success message)
            console.log('CSV file uploaded successfully', exportSuccessful);
          } else {
            // Handle failure (e.g., show an error message)
            console.error('Error uploading CSV file', exportSuccessful);
          }
        } catch (error) {
          console.error('Error uploading CSV file', error);
        }
      }
    },
  },
});
